import React, { Component } from "react";

export default class Experiences extends Component {
  render() {
    return (
      <div>
        <div
          className="card  lighten-3 z-depth-0"
          style={{ backgroundColor: "#fcf7ff" }}
        >
          <div className="card-content">
            <h6 className="black-text text-darken-4">
              <strong>Work </strong>
            </h6>
            <hr />
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>Software Engineer-Specialist</strong>
                  <span> Feb 2022 - Present</span>
                </h6>
                <p className="pt black-text text-darken-4">
                  American Century Investments
                </p>
              </div>
            </div>
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>Software Engineer Associate</strong>
                  <span> May 2019 - Feb 2022</span>
                </h6>
                <p className="pt black-text text-darken-4">
                  American Century Investments
                </p>
              </div>
            </div>
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>Youth Ministry Assistant</strong>
                  <span> March 2021 - Present</span>
                </h6>
                <p className="pt black-text text-darken-4">
                  Saint Andrew the Apostle-Gladstone
                </p>
              </div>
            </div>

            <h6 className="black-text text-darken-4">
              <strong>Education </strong>
            </h6>
            <hr />
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>College of the Ozarks </strong>
                  <span> 2015 - 2019</span>
                </h6>
                <p className="pt black-text text-darken-4">
                  Bachelor of Science | Computer Science and Mathematics
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
