import React, { Component } from "react";

export default class Community extends Component {
  render() {
    return (
      <div>
        <div
          className="card  lighten-3 z-depth-0"
          style={{ backgroundColor: "#84a98c" }}
        >
          <div className="card-content">
            <h6 className="white-text text-darken-4">
              <strong>Community Involvement</strong>
            </h6>
            <hr />
            <div className="row mt">
              <div className="col s12">
                <h6 className="white-text text-darken-4">
                  <strong>Merging KC</strong>
                  <span> Board Member</span>
                </h6>
                <h6 className="white-text text-darken-4">
                  <strong>Humble In Heart </strong>
                  <span> Board Member</span>
                </h6>
                <h6 className="white-text text-darken-4">
                  <strong>Saint Andrew the Apostle</strong>
                  <span> Youth Ministry Assistant</span>
                </h6>
                <h6 className="white-text text-darken-4">
                  <strong>Kansas City Young Catholic Professionals</strong>
                  <span> Member</span>
                </h6>
                <h6 className="white-text text-darken-4">
                  <strong>Redemptorist</strong>
                  <span>
                    Website Committee Member | Young Adult Committee Member
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
