import React, { Component } from "react";

export default class Educations extends Component {
  render() {
    return (
      <div>
        <div className="card  lighten-3 z-depth-0">
          <div className="card-content">
            <h6 className="brown-text text-darken-4">
              <strong>
                <i className="fas fa-graduation-cap"></i> EDUCATION
              </strong>
            </h6>
            <hr />
            <div className="row mt">
              <div className="col s12">
                <blockquote>
                  <h6 className="brown-text text-darken-4">
                    <strong>College of the Ozarks </strong>
                    <span> - 2019</span>
                  </h6>
                  <p className="pt brown-text text-darken-4">
                    Bachelor of Science | Computer Science and Mathematics
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
