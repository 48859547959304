import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div>
        <div className="card brown lighten-2 z-depth-0">
          <div className="card-content social right">
            <h2 className="white-text text-darken-4 right-align">
              <strong>Kara Elliott</strong>
            </h2>
            <h5 className="white-text text-darken-2 right-align">
              Software Engineer-Specialist
            </h5>
          </div>
        </div>
      </div>
    );
  }
}
