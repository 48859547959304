import React, { Component } from "react";

export default class Skills extends Component {
  render() {
    return (
      <div>
        <div
          className="card  lighten-1 z-depth-0"
          style={{ backgroundColor: "#84a98c" }}
        >
          <div className="card-content">
            <h6 className="white-text">
              <strong> Technologies | Languages | Tools</strong>
            </h6>
            <hr />
            <div className="row pt">
              <div className="col m4 s12">
                <p class="white-text text-lighten-2">React</p>
                <p class="white-text text-lighten-2">React Native</p>
                <p class="white-text text-lighten-2">Google Firebase</p>
                <p class="white-text text-lighten-2">AWS Lambda</p>
                <p class="white-text text-lighten-2">AWS S3</p>
                <p class="white-text text-lighten-2">Openshift</p>
              </div>
              <div className="col m4 s12">
                <p class="white-text text-lighten-2">Java</p>
                <p class="white-text text-lighten-2">HTML | CSS</p>
                <p class="white-text text-lighten-2">Python</p>
                <p class="white-text text-lighten-2">SQL</p>
                <p class="white-text text-lighten-2">Javascript</p>
                <p class="white-text text-lighten-2">Typescript</p>
              </div>
              <div className="col m4 s12">
                <p class="white-text text-lighten-2">VS Code</p>
                <p class="white-text text-lighten-2">Material UI</p>
                <p class="white-text text-lighten-2">Bootstrap</p>
                <p class="white-text text-lighten-2">Spring Tool Suite</p>
                <p class="white-text text-lighten-2">Android Studio</p>
                <p class="white-text text-lighten-2">Rapid SQL</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
