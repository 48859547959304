import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div>
        <div
          className="card  lighten-2 z-depth-0 mt"
          style={{ backgroundColor: "#84a98c" }}
        >
          <div className="card-content contactInfo">
            <p className="white-text text-darken-4 pt">
              <i className="fas fa-map-marker-alt"></i> Kansas City, Missouri
              <br />
              <i className="fas fa-envelope-square"></i> karaelliott03@gmail.com
              <br />
              <i className="fab fa-linkedin"></i>{" "}
              www.linkedin.com/in/kara-elliott03
            </p>
          </div>
        </div>
      </div>
    );
  }
}
