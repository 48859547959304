import React, { Component } from "react";

export default class Sites extends Component {
  render() {
    return (
      <div>
        <div
          className="card  lighten-3 z-depth-0"
          style={{ backgroundColor: "#fcf7ff" }}
        >
          <div className="card-content">
            <h6 className="black-text text-darken-4">
              <strong>Sites</strong>
            </h6>
            <hr />
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>Merging KC </strong> <br />
                  <span>
                    <a href="https://mergingkc.org/"> https://mergingkc.org/</a>
                  </span>
                </h6>
                <p className="pt black-text text-darken-4">
                  Merging KC is a 501c3 Organization in the Kansas City area
                  focused on bridging the gap and creating the ultimate
                  collaboration to end homelessness and restore green spaces in
                  the community.
                </p>
              </div>
            </div>
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>Redemptorist </strong> <br />
                  <span>
                    <a href="https://www.redemptoristkc.org/">
                      https://www.redemptoristkc.org/
                    </a>
                  </span>
                </h6>
                <p className="pt black-text text-darken-4">
                  Redemptorist is a Catholic Church in the Kansas City-Saint
                  Joseph Diocese
                </p>
              </div>
            </div>
            <div className="row mt">
              <div className="col s12">
                <h6 className="black-text text-darken-4">
                  <strong>Humble in Heart </strong> <br />
                  <span>
                    <a href="https://humbleinheartkc.com/">
                      https://humbleinheartkc.com/
                    </a>
                  </span>
                </h6>
                <p className="pt black-text text-darken-4">
                  Catholic Mental Health Group
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
