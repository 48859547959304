import { BrowserRouter as Router, Route } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import Home from "./components/pages/Home";
import "./App.css";

function App() {
  return (
    <div className="wrap-content">
      <div
        className="container lighten-2"
        style={{ backgroundColor: "#354f52" }}
      >
        <Router>
          <Route exact path="/" component={Home} />
        </Router>
      </div>
    </div>
  );
}

export default App;
