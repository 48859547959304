import React, { Component } from "react";
import ImgProfile from "../../images/profile.jpg";

export default class Profile extends Component {
  render() {
    return (
      <div>
        <div className="avatarImg">
          <img
            className="circle responsive-img"
            src={ImgProfile}
            alt="Kara Elliott"
          />
        </div>
      </div>
    );
  }
}
